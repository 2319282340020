<template>
    <div class="page-app">

        <div id="bookings-page" class="col-12">
            <div class="card border-0 shadpw-sm">
                <div class="card-header border-0 bg-white py-3">
                    <div class="d-md-flex d-block justify-content-between">
                        <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
                            <h1>Reservas</h1>
                        </div>

                    </div>
                    <div class="d-lg-flex d-block justify-content-between">
                        <div class="d-flex align-items-center mb-3 mb-lg-0">
                            <MazSelect v-model="selectedValueFilterState" :options="optionsFilterState" @update:model-value="start()" class="me-2" />

                            <div class="dropdown">
                                <a class="btn btn-secondary mb-0 d-flex align-items-center" role="button" id="dropdownFilters" data-bs-toggle="dropdown" aria-expanded="false">

                                    <span class="d-none d-sm-flex"><strong class="me-1">Fecha </strong>
                                        <span v-if="selectedValueFilterDate == 0">Creación</span>
                                        <span v-else-if="selectedValueFilterDate == 1">Check-in</span>
                                        <span v-else-if="selectedValueFilterDate == 2">Check-out</span>
                                        <span v-else-if="selectedValueFilterDate == 3">Modificación</span>
                                    </span>
                                    <div class="d-flex align-items-center">
                                        <span class="d-inline-block d-sm-none">Filtrar</span>
                                        <i class="fa-regular fa-filter ms-2"></i>
                                    </div>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownFilters" style="min-width: 270px">
                                    <li><a class="dropdown-item" href="#" @click="filterByDate(1)">Fecha
                                            de check-in</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click="filterByDate(2)">Fecha
                                            de check-out</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click="filterByDate(0)">Fecha
                                            de creación</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click="filterByDate(3)">Fecha
                                            de
                                            modificación</a></li>

                                </ul>
                            </div>
                        </div>

                        <div class="d-lg-flex justify-content-end">
                            <div class="searchBar mb-3 mb-lg-0">
                                <!-- Filter Search -->
                                <div id="search-input" class="input-group">
                                    <MazInput v-model="searchQuery" placeholder="Buscar...">
                                        <template #left-icon>
                                            <i class="fa-regular fa-magnifying-glass ms-2"></i>
                                        </template>
                                    </MazInput>
                                </div>
                            </div>
                            <div class="mb-1">
                                <DatePicker v-model.range="range" :masks="masks" :columns="columns">
                                    <template #default="{ togglePopover, inputValue }">
                                        <button class="btn px-3 py-2 bg-blue-500 text-smfont-semibold" @click="togglePopover" id="calendar-btn">
                                            <i class="fa-regular fa-calendar-days me-3 text-primary"></i>
                                            <span>{{ inputValue.start }}</span>
                                            <i class="fa-regular fa-arrow-right mx-2"></i>
                                            <span>{{ inputValue.end }}</span>
                                        </button>
                                    </template>
                                </DatePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="!loading" id="bookingsTable" class="table-responsive mb-3">
                        <EasyDataTable :headers="headers" :items="items" :server-items-length="serverItemsLength" v-model:server-options="serverOptions" :rows-per-page="50" :loading="loadingData"
                            rows-per-page-message="resultados por página" rows-of-page-separator-message="de" :rows-items="[25, 50, 100]" table-class-name="customize-table">
                            <template #item-operation="item">
                                <div class="operation-wrapper">
                                    <div class="d-flex align-items-center">
                                        <RouterLink :to="'bookings/view/' + item.id" class="btn btn-primary me-2">
                                            <i class="fa-regular fa-eye"></i>
                                        </RouterLink>
                                    </div>
                                </div>
                            </template>
                            <template #item-id="{ id }">
                                <div class="badge badge-secondary">
                                    {{ "#" + id }}
                                </div>
                            </template>
                            <template #item-portal="{ portal }">
                                <div class="badge badge-light-info">
                                    {{ portal }}
                                </div>
                            </template>
                            <template #item-total_amount="{ total_amount }">
                                <div class="total-wrapper">
                                    <div class="">{{ formatCurrency(total_amount) }}</div>
                                </div>
                            </template>
                            <template #item-checkin="{ checkin }">
                                <div style="width: 150px;">{{ checkin }}</div>

                            </template>

                            <template #item-creation_date="{ creation_date }">
                                <div style="width: 150px;">{{ creation_date }}</div>

                            </template>

                            <template #item-dateLastModified="{ dateLastModified }">
                                <div style="width: 150px;">{{ dateLastModified }}</div>
                            </template>


                            <template #item-persons="{ persons }">
                                <div class="type-wrapper">
                                    <div class="text-center">{{ persons }}</div>
                                </div>
                            </template>

                            <template #item-client="{ client }">
                                <div class="type-wrapper">
                                    <div class="text-uppercase">{{ client }}</div>
                                </div>
                            </template>

                            <template #item-rooms="{ rooms }">
                                <div class="type-wrapper">
                                    <ul class="ps-3">
                                        <li v-for="item in rooms" :key="item">
                                            <span>{{ item.name }}<span v-if="item.quantity > 1" class="ms-2">{{
                                                "x" + item.quantity }}</span></span>
                                        </li>
                                    </ul>

                                </div>
                            </template>

                            <template #item-status="{ status, isPaid }">
                                <div class="type-wrapper">
                                    <span v-if="status == 4" class="badge badge-primary me-1 text-uppercase mb-1">Confirmado</span>
                                    <span v-else-if="status == 7" class="badge badge-danger me-1 text-uppercase mb-1">Cancelado</span>
                                    <span v-else-if="status == 5" class="badge badge-danger me-1 text-uppercase mb-1">Rechazado</span>
                                    <span v-else-if="status == 8" class="badge badge-warning me-1 text-uppercase mb-1">Modificado</span>
                                    <span v-else-if="status == 6" class="badge badge-dark me-1 text-uppercase mb-1">No
                                        show</span>

                                    <span v-if="isPaid" class="badge badge-success me-1 text-uppercase"><i class="fa-regular fa-circle-check me-2 "></i>Pagado</span>

                                </div>
                            </template>

                            <template #item-ses_status="{ ses_status }">
                                <div class="type-wrapper">
                                    <span v-if="ses_status == -1">-</span>
                                    <span v-else-if="ses_status == 0" class="badge badge-success me-1 text-uppercase mb-1">Success</span>
                                    <span v-else-if="ses_status == 1" class="badge badge-warning me-1 text-uppercase mb-1">Modified</span>
                                    <span v-else-if="ses_status == 2" class="badge badge-danger me-1 text-uppercase mb-1">Error</span>
                                    <span v-else-if="ses_status == 3" class="badge badge-warning me-1 text-uppercase mb-1">Skipped</span>
                                </div>
                            </template>

                            <template #loading>
                                <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>


                        </EasyDataTable>
                    </div>

                    <div v-if="loading" id="spinner-search" class="col-12 text-center py-5">
                        <div class="spinner-border text-primary" role="status" style="width: 2rem; height: 2rem; visibility: visble;">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div>Buscando reservas...</div>
                    </div>

                    <!-- <div v-if="bookings.length == 0 && !loading" class="col-12 text-center pt-5 pb-3">No
                        se ha encontrado reservas para las fechas seleccionadas.</div> -->
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import BookingService from "@/services/booking.service";

import _ from "lodash";

import { useScreens } from 'vue-screen-utils';

export default {
    name: 'bookings',
    components: {},
    setup() {
        const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px', });
        const columns = mapCurrent({ lg: 2 }, 1);

        return {
            columns
        }
    },
    data() {
        return {
            bookings: [],
            searchQuery: '',
            loading: true,
            loadingData: true,
            range: {
                start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            },
            masks: {
                input: 'YYYY-MM-DD',
            },
            selectedValueFilterDate: 0,
            selectedValueFilterState: "all",
            optionsFilterState: [
                { label: "Todos", value: "all" },
                { label: "Confirmado", value: "confirmed" },
                { label: "Cancelado", value: "cancelled" },
                { label: "No show", value: "noshow" },
                { label: "Pagado", value: "paid" },
                { label: "Pendiente de pago", value: "pendingpaid" },
            ],
            serverOptions: {
                page: 1,
                rowsPerPage: 25,
                sortBy: "dateLastModified",
                sortType: "desc",
            },
            headers: [],
            items: [],
            serverItemsLength: 0,
            defaultCurrency: '',
        }
    },
    watch: {
        range: {
            handler: function () {
                this.onDateRangeChange();
            },
            deep: true
        },

        searchQuery(value) {
            this.getBookings();
        },
        serverOptions(value) {
            this.getBookings();
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true
            this.bookings = []
            await this.getBookings();
            this.headers = [
                { text: "", value: "operation" },
                { text: "PMS ID", value: "id", sortable: true },
                { text: "Portal", value: "portal", sortable: true },
                {
                    text: "Checkin",
                    value: "checkin",
                    sortable: true,
                },
                { text: "Checkout", value: "checkout", sortable: true },
                { text: "Habitaciones", value: "rooms", sortable: true },
                { text: "Personas", value: "persons", sortable: true },
                { text: "Precio", value: "total_amount", sortable: true },
                { text: "Cliente", value: "client", sortable: true },
                { text: "Estado", value: "status", sortable: true },
                { text: "Fecha Creación", value: "creation_date", sortable: true },
                { text: "Fecha Modificación", value: "dateLastModified", sortable: true },
                { text: "SES", value: "ses_status", sortable: true },
            ];

            this.loading = false
        },

        onDateRangeChange() {
            this.start();
        },
        onChangeSearchType() {
            this.start();
        },

        filterByDate(key) {
            this.selectedValueFilterDate = key
            this.onChangeSearchType()

        },


        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMgetUTCMonthonth() + 1),
                day = '' + d.getUTCDate(),
                year = d.getUTCFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },


        async getBookings() {
            this.loadingData = true;
            this.items = []
            var response = await BookingService.getAllBookings({
                searchtype_text: this.searchQuery,
                searchtype: this.selectedValueFilterDate,
                searchtype_status: this.selectedValueFilterState,
                startDate: this.formatDate(this.range.start),
                endDate: this.formatDate(this.range.end),
                page: this.serverOptions.page,
                rowsPerPage: this.serverOptions.rowsPerPage,
                sortBy: this.serverOptions.sortBy,
                sortType: this.serverOptions.sortType,
            });

            console.log(response.data, 'Data')
            this.bookings = response.data.bookings;
            this.serverItemsLength = response.data.serverItemsLength
            // console.log(this.bookings, 'Bookings')

            await this.prepareBookings();
            this.loadingData = false;
        },

        async prepareBookings() {

            this.items = this.bookings.map(booking => {
                const creation_date = this.formatDateHour(new Date(booking.creation_date), true);
                const dateLastModified = this.formatDateHour(new Date(booking.dateLastModified), true);
                const portal = booking.portal ? booking.portal.name : "";
                const client = `${booking.firstName} ${booking.lastName}`;
                const persons = booking.adults + booking.children;

                // Optimiza la creación de la lista de habitaciones con reduce.
                const rooms = booking.rooms.reduce((acc, room) => {
                    if (room.roomType) {
                        const RT_name = room.roomType.name;
                        const roomIndex = acc.findIndex(r => r.name === RT_name);
                        if (roomIndex >= 0) {
                            acc[roomIndex].quantity += 1;
                        } else {
                            acc.push({ name: RT_name, quantity: 1 });
                        }
                    }
                    return acc;
                }, []);

                return {
                    id: booking.id,
                    portal: portal,
                    checkin: booking.checkin,
                    checkout: booking.checkout,
                    total_amount: booking.total_amount,
                    status: booking.status,
                    isPaid: booking.isPaid,
                    creation_date: creation_date,
                    dateLastModified: dateLastModified,
                    client: client,
                    persons: persons,
                    rooms: rooms,
                    ses_status: booking.ses_status
                };
            });

            // console.log(this.items, "Invoices")
        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        formatDateHour(date, hour = boolean) {
            var days = ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"];
            var months = [
                "ene",
                "feb",
                "mar",
                "abr",
                "may",
                "jun",
                "jul",
                "ago",
                "sep",
                "oct",
                "nov",
                "dic",
            ];
            var currentDate = new Date(date);

            if (hour) {
                currentDate =
                    currentDate.getDate() +
                    " " +
                    months[currentDate.getMonth()] +
                    " " +
                    currentDate.getFullYear() +
                    " - " +
                    currentDate.getHours() +
                    ":" +
                    (currentDate.getMinutes() < 10 ? "0" : "") +
                    currentDate.getMinutes();
            } else {
                currentDate =
                    currentDate.getDate() +
                    " " +
                    months[currentDate.getMonth()] +
                    " " +
                    currentDate.getFullYear();
            }

            return currentDate;
        },


    },
    created() {
        // console.log('Componente creado');

        this.start();
    },
    mounted() {
        // console.log('Componente montado');

        if (!this.currentUser) {
            this.$router.push('/login');
        }

        this.$nextTick(() => {
            console.log('Estado del DOM después de montar:', document.readyState);

            window.scrollTo(0, 0);
        });
    },
    updated() {
        // console.log('Componente actualizado');
    }
};
</script>

<style>
#bookings-page #search-input {
    margin-right: 10px;
}

#bookings-page #calendar-btn {
    padding: 10px;
    border-width: 0.125rem;
    border-radius: 0.5rem;
    width: auto;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    color: #212427;
    height: 3em;
}

#bookings-page #bookingsTable .customize-table table {
    border-collapse: collapse !important;
}

#bookings-page #bookingsTable .customize-table th {
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 12px;
}

#bookings-page #bookingsTable .customize-table table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state,
            var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,
            var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

#bookings-page #bookingsTable .customize-table td {
    border: none;
    font-size: 12px;

}

#bookings-page #bookingsTable .customize-table table tr {
    border-bottom-width: 1px !important;
    border-bottom-style: dashed !important;
    border-bottom-color: var(--bs-border-color) !important;
}

#bookings-page #bookingsTable td {
    min-width: auto;
}

#bookings-page #bookingsTable td:first-child {
    min-width: auto;
}

#bookings-page #bookingsTable .customize-table {
    --easy-table-border: 0;
    --easy-table-row-border: 1px solid rgba(230, 230, 230, 0.7);

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: #000;
    --easy-table-header-background-color: #fff;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: #000;
    --easy-table-body-row-background-color: #fff;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 14px;

    --easy-table-body-row-hover-font-color: #2d3a4f;
}

@media screen and (max-width: 992px) {
    #bookings-page #search-input {
        margin-right: 0;
    }

    #bookings-page #search-input .m-input {
        width: 100%;
    }

    #bookings-page #calendar-btn {
        width: 100%;
    }
}
</style>