<template>
    <div class="page-app">
        <div class="row">
            <div class="col-md-12">
                <div class="card border-0 p-3 shadow-sm mb-3">
                    <div class="d-flex card-header bg-white border-0">
                        <div class="d-flex justify-content-between">
                            <div class="modal-title mb-0 d-flex align-items-center">
                                <i class="fa-regular fa-user me-2 text-primary fs-3"></i>
                                <div class="fs-4" style="font-weight: 500">
                                    {{ "Cliente #" + clientId }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <div v-if="!loading">
                            <form @submit="updateClient">
                                <div class="row mx-0">
                                    <div class="col-md-12">
                                        <div class="row mb-5">
                                            <div class="col-md-6 mb-4">
                                                <h6 class="mb-3">
                                                    Información personal
                                                </h6>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Nombre</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="firstName" type="text" class="form-control form-control-sm" v-model="client.firstName
                                                            " />
                                                        <div id="validation-firstName" class="invalid-feedback">
                                                            Introduce el nombre.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Apellido 1</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="lastName1" type="text" class="form-control form-control-sm" v-model="client.lastName1
                                                            " />
                                                        <div id="validation-lastName1" class="invalid-feedback">
                                                            Introduce el primer
                                                            apellido.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Apellido 2</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="lastName2" type="text" class="form-control form-control-sm" v-model="client.lastName2
                                                            " />
                                                        <div id="validation-lastName2" class="invalid-feedback">
                                                            Introduce el segundo
                                                            apellido.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Email</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input type="email" class="form-control form-control-sm" v-model="client.email
                                                            " />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Teléfono</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.phone
                                                            " />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Sexo</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="sex" class="form-select form-select-sm" v-model="client.sex">
                                                            <option value="m">
                                                                Masculino
                                                            </option>
                                                            <option value="f">
                                                                Femenino
                                                            </option>
                                                        </select>
                                                        <div id="validation-sex" class="invalid-feedback">
                                                            Selecciona el sexo.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Fecha de
                                                            nacimiento</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="dateofbirth" type="date" class="form-control form-control-sm" v-model="client.dateofbirth
                                                            " />
                                                        <div id="validation-dateofbirth" class="invalid-feedback">
                                                            Introduce una fecha
                                                            válida.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Nacionalidad</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="nationality" class="form-select form-select-sm" v-model="client.nationality
                                                            ">
                                                            <option :key="c" v-for="c in countries" :value="c.id">
                                                                {{ c.name }}
                                                            </option>
                                                        </select>
                                                        <div id="validation-nationality" class="invalid-feedback">
                                                            Selecciona la
                                                            nacionalidad.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h6 class="mb-3">Documentos</h6>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Tipo de
                                                            documento</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="document_type" class="form-select form-select-sm" v-model="client.document_type
                                                            ">
                                                            <option value="dni" v-if="client.nationality ==
                                                                66
                                                            ">
                                                                DNI
                                                            </option>
                                                            <option value="passport">
                                                                Pasaporte
                                                            </option>
                                                            <option value="driving_license">
                                                                Carné de
                                                                conducir
                                                            </option>
                                                            <option value="identity_cart_or_document">
                                                                Carta o
                                                                Documento de
                                                                Identidad
                                                            </option>
                                                            <option value="residence_cart">
                                                                Tarjeta de
                                                                residencia
                                                                España
                                                            </option>
                                                            <option value="residence_cart_country_ue">
                                                                Tarjeta de
                                                                residencia País
                                                                UE
                                                            </option>
                                                            <option value="citizenship_card">
                                                                Cédula de ciudadanía
                                                            </option>

                                                            <option value="foreign_identity_card">
                                                                Cédula de extranjería
                                                            </option>

                                                            <option value="civil_registry">
                                                                Registro civil
                                                            </option>

                                                            <option value="other">
                                                                Otro
                                                            </option>
                                                        </select>
                                                        <div id="validation-document_type" class="invalid-feedback">
                                                            Selecciona el tipo
                                                            de documento.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Nº documento</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="nif" type="text" class="form-control form-control-sm" v-model="client.nif" />
                                                        <div id="validation-nif" class="invalid-feedback">
                                                            Introduce el número
                                                            del documento.
                                                            Verifica que está
                                                            bien escrito.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Nº de soporte</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="nif" type="text" class="form-control form-control-sm" v-model="client.document_support" />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Fecha
                                                            Expedición</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="document_expeditionDate" type="date" class="form-control form-control-sm" v-model="client.document_expeditionDate
                                                            " />
                                                        <div id="validation-document_expeditionDate" class="invalid-feedback">
                                                            Introduce una fecha
                                                            válida.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Fecha
                                                            Expiración</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input id="document_experationDate" type="date" class="form-control form-control-sm" v-model="client.document_experationDate
                                                            " />
                                                        <div id="validation-document_experationDate" class="invalid-feedback">
                                                            Introduce una fecha
                                                            válida.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <h6 class="mb-3">Dirección</h6>

                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Dirección</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.address
                                                            " />
                                                    </div>
                                                </div>

                                                <div class="row align-items-center mb-2" v-if="client.country == 66">
                                                    <div class="col-4">
                                                        <label class="form-label">Provincia</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="region" class="form-select form-select-sm" v-model="client.region
                                                            ">
                                                            <option :key="r" v-for="r in regions" :value="r.id">
                                                                {{ r.name }}
                                                            </option>
                                                        </select>
                                                        <div id="validation-region" class="invalid-feedback">
                                                            Selecciona una
                                                            provincia.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label for="inputCity" class="form-label">Ciudad
                                                            y CP</label>
                                                    </div>
                                                    <div class="col-4">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.locality
                                                            " placeholder="Ciudad" />
                                                    </div>
                                                    <div class="col-4">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.postal_code
                                                            " placeholder="Código Postal" />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">País</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="country" class="form-select form-select-sm" v-model="client.country
                                                            ">
                                                            <option :key="c" v-for="c in countries" :value="c.id">
                                                                {{ c.name }}
                                                            </option>
                                                        </select>
                                                        <div id="validation-country" class="invalid-feedback">
                                                            Selecciona un país.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h6 class="mb-3">
                                                    Dirección de facturación
                                                </h6>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">Dirección</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.tax_address
                                                            " />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2" v-if="client.country == 66">
                                                    <div class="col-4">
                                                        <label class="form-label">Provincia</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select id="tax_region" class="form-select form-select-sm" v-model="client.tax_region
                                                            ">
                                                            <option :key="r" v-for="r in regions" :value="r.id">
                                                                {{ r.name }}
                                                            </option>
                                                        </select>
                                                        <div id="validation-tax_region" class="invalid-feedback">
                                                            Selecciona una
                                                            provincia.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label for="inputCity" class="form-label">Ciudad
                                                            y CP</label>
                                                    </div>
                                                    <div class="col-4">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.tax_locality
                                                            " placeholder="Ciudad" />
                                                    </div>
                                                    <div class="col-4">
                                                        <input type="text" class="form-control form-control-sm" v-model="client.tax_postal_code
                                                            " placeholder="Código Postal" />
                                                    </div>
                                                </div>
                                                <div class="row align-items-center mb-2">
                                                    <div class="col-4">
                                                        <label class="form-label">País</label>
                                                    </div>
                                                    <div class="col-8">
                                                        <select class="form-select form-select-sm" v-model="client.tax_country
                                                            ">
                                                            <option :key="c" v-for="c in countries" :value="c.id">
                                                                {{ c.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h6 class="mb-3">
                                                    Observaciones
                                                </h6>
                                                <div class="col-md-12">
                                                    <textarea type="text" class="form-control" v-model="client.notes" rows="3"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h6 class="mb-3">Alergias</h6>
                                                <div class="col-md-12">
                                                    <textarea type="text" class="form-control" v-model="client.alergies
                                                        " rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid gap-2 col-6 mx-auto mt-5">
                                    <button type="submit" id="submitUpdateClient" class="btn btn-primary">
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando Información...</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-5">
                <div class="card border-0 p-3 shadow-sm">
                    <div class="d-flex card-header bg-white border-0">
                        <div class="d-flex justify-content-between">
                            <div class="modal-title mb-0 d-flex align-items-center">
                                <i class="fa-regular fa-list-timeline me-2 text-primary fs-3"></i>
                                <div class="fs-4" style="font-weight: 500">
                                    Historial cliente
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <div v-if="!loading" class="table-responsive" style="overflow: auto; max-height: 400px">
                            <table class="table table-row-dashed">
                                <thead>
                                    <tr>
                                        <th>Hotel</th>
                                        <th>Fechas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="register" v-for="register in history" class="align-middle">
                                        <td>
                                            <div>{{ register.hotel.name }}</div>
                                        </td>
                                        <td>
                                            <div v-if="register.booking">
                                                {{
                                                    formatDate(
                                                        register.booking.checkin
                                                    ) +
                                                    " al " +
                                                    formatDate(
                                                        register.booking
                                                            .checkout
                                                    )
                                                }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else id="spinner-search" class="col-12 text-center py-5">
                            <div class="spinner-border text-primary" role="status" style="
									width: 2rem;
									height: 2rem;
									visibility: visble;
								">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div>Cargando Información...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import BookingService from "../../services/booking.service";
import ClientService from "../../services/client.service";
import CompanyService from "../../services/company.service";
import CountryService from "../../services/country.service";
import RegionService from "../../services/region.service";

import Swal from "sweetalert2";

export default {
    name: "Client",
    data() {
        return {
            clientId: this.$route.params.clientId,
            client: [],
            clients: null,
            companies: null,
            regions: null,
            countries: null,
            history: [],
            loading: true,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getClient();
            await this.getCountries();
            await this.getRegions();
            await this.getCompanies();
            await this.getHistory();
            this.loading = false;
        },

        async updateClient(evt) {
            evt.preventDefault();

            var now = new Date();
            var error = false;

            // RESETAMOS LOS ERRORES

            window.$("#validation-firstName").css("display", "none");
            window.$("#firstName").removeClass("is-invalid");

            window.$("#validation-lastName1").css("display", "none");
            window.$("#lastName1").removeClass("is-invalid");

            window.$("#validation-lastName2").css("display", "none");
            window.$("#lastName2").removeClass("is-invalid");

            window.$("#validation-sex").css("display", "none");
            window.$("#sex").removeClass("is-invalid");

            window.$("#validation-dateofbirth").css("display", "none");
            window.$("#dateofbirth").removeClass("is-invalid");

            window.$("#validation-document_type").css("display", "none");
            window.$("#document_type").removeClass("is-invalid");

            window.$("#validation-nif").css("display", "none");
            window.$("#nif").removeClass("is-invalid");

            window
                .$("#validation-document_expeditionDate")
                .css("display", "none");
            window.$("#document_expeditionDate").removeClass("is-invalid");

            window
                .$("#validation-document_experationDate")
                .css("display", "none");
            window.$("#document_experationDate").removeClass("is-invalid");

            window.$("#validation-country").css("display", "none");
            window.$("#country").removeClass("is-invalid");

            // COMPROBAMOS EL CAMPO firstName
            if (
                this.client.firstName == undefined ||
                this.client.firstName == ""
            ) {
                window.$("#validation-firstName").css("display", "block");
                window.$("#firstName").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO lastName1
            if (
                this.client.lastName1 == undefined ||
                this.client.lastName1 == ""
            ) {
                window.$("#validation-lastName1").css("display", "block");
                window.$("#lastName1").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO lastName2
            if (
                (this.client.lastName2 == undefined ||
                    this.client.lastName2 == "") &&
                this.client.nationality == 66
            ) {
                window.$("#validation-lastName2").css("display", "block");
                window.$("#astName2").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO email
            if (this.client.email == undefined || this.client.email == "") {
                this.client.email = null;
            }

            // COMPROBAMOS EL CAMPO sex
            if (this.client.sex == undefined || this.client.sex == "") {
                window.$("#validation-sex").css("display", "block");
                window.$("#sex").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS QUE LA FECHA DE NACIMIENTO SEA MENOR A LA FECHA DE CHECKIN
            var dateofbirth = new Date(this.client.dateofbirth);
            if (this.client.dateofbirth == undefined || dateofbirth >= now) {
                window.$("#validation-dateofbirth").css("display", "block");
                window.$("#dateofbirth").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO document_type
            if (
                this.client.document_type == undefined ||
                this.client.document_type == ""
            ) {
                window.$("#validation-document_type").css("display", "block");
                window.$("#document_type").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO nif
            if (this.client.nif == undefined || this.client.nif == "") {
                window.$("#validation-nif").css("display", "block");
                window.$("#nif").addClass("is-invalid");
                error = true;
            } else if (this.client.document_type == "dni") {
                var result = await this.validateDNI(this.client.nif);

                if (!result) {
                    window.$("#validation-nif").css("display", "block");
                    window.$("#nif").addClass("is-invalid");
                }
            }

            // COMPROBAMOS EL CAMPO document_expeditionDate
            var document_expeditionDate = new Date(
                this.client.document_expeditionDate
            );
            if (
                this.client.document_expeditionDate == undefined ||
                document_expeditionDate >= now
            ) {
                window
                    .$("#validation-document_expeditionDate")
                    .css("display", "block");
                window.$("#document_expeditionDate").addClass("is-invalid");
                error = true;
            }

            // COMPROBAMOS EL CAMPO document_experationDate SOLO SI EL TIPO DE DOCUMENTO NO ES "civil_registry" o "citizenship_card"
            if (this.client.document_type !== "civil_registry" && this.client.document_type !== "citizenship_card") {
                var document_experationDate = new Date(this.client.document_experationDate);
                if (
                    this.client.document_experationDate == undefined ||
                    document_experationDate <= this.client.document_expeditionDate ||
                    document_experationDate < now
                ) {
                    window.$("#validation-document_experationDate").css("display", "block");
                    window.$("#document_experationDate").addClass("is-invalid");
                    error = true;
                }
            }

            // COMPROBAMOS EL CAMPO country
            if (this.client.country == undefined || this.client.country == "") {
                window.$("#validation-country").css("display", "block");
                window.$("#country").addClass("is-invalid");
                error = true;
            }

            if (!error) {
                window.$("#submitBtn" + this.id).empty();
                window
                    .$("#submitUpdateClient" + this.id)
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                let response = await ClientService.updateClient(
                    this.clientId,
                    this.client
                );

                window.$("#submitUpdateClient" + this.id).empty();
                window.$("#submitUpdateClient" + this.id).append("Guardar");
                if (response.status == 200) {
                    Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Se ha guardado correctamente.",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
        },

        async getClient() {
            var response = await ClientService.getClient(this.clientId);
            this.client = response.data;
            console.log(this.client, "Client")
        },
        async getCountries() {
            this.countries = await CountryService.getAllCountries();
        },
        async getRegions() {
            var response = await RegionService.getAllRegions();
            this.regions = response.data;
        },
        async getCompanies() {
            var response = await CompanyService.getAllCompanies();
            this.companies = response.data;
        },

        async getHistory() {
            var response = await BookingService.getGuestsByClient({
                client: this.clientId,
            });

            this.history = response.data;

            console.log(this.history, "Historial");
        },

        async validateDNI(dni) {
            var numero, let2, letra;
            var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

            dni = dni.toUpperCase();

            if (expresion_regular_dni.test(dni) === true) {
                numero = dni.substr(0, dni.length - 1);
                numero = numero.replace("X", 0);
                numero = numero.replace("Y", 1);
                numero = numero.replace("Z", 2);
                let2 = dni.substr(dni.length - 1, 1);
                numero = numero % 23;
                letra = "TRWAGMYFPDXBNJZSQVHLCKET";
                letra = letra.substring(numero, numero + 1);
                if (letra != let2) {
                    //alert('Dni erroneo, la letra del NIF no se corresponde');
                    return false;
                } else {
                    //alert('Dni correcto');
                    return true;
                }
            } else {
                //alert('Dni erroneo, formato no válido');
                return false;
            }
        },

        formatDate(date, hour = false) {
            var days = ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"];
            var months = [
                "ene",
                "feb",
                "mar",
                "abr",
                "may",
                "jun",
                "jul",
                "ago",
                "sep",
                "oct",
                "nov",
                "dic",
            ];
            var currentDate = new Date(date);

            if (hour) {
                currentDate =
                    currentDate.getDate() +
                    " " +
                    months[currentDate.getMonth()] +
                    " " +
                    currentDate.getFullYear() +
                    " - " +
                    currentDate.getHours() +
                    ":" +
                    (currentDate.getMinutes() < 10 ? "0" : "") +
                    currentDate.getMinutes();
            } else {
                currentDate =
                    currentDate.getDate() +
                    " " +
                    months[currentDate.getMonth()] +
                    " " +
                    currentDate.getFullYear();
            }

            return currentDate;
        },
    },
    created() {
        this.start();
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push("/login");
        }
    },
};
</script>

<style scoped>
th {
    text-transform: uppercase;
    font-weight: 500 !important;
}

td {
    border: none;
}

.table:not(.table-bordered).table-row-dashed tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
}
</style>