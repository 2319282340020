<template>
    <div>
        <button class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#modalAddClient">
            <i class="fa-regular fa-plus me-2"></i>Añadir cliente
        </button>
        <!-- Modal Add CLient-->
        <div class="modal fade" id="modalAddClient" tabindex="-1" aria-labelledby="modalAddClientTitle" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">

                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAddClientTitle">Añadir Huésped</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body p-3">
                        <div class="row mb-5">
                            <div class="col-md-6 mb-4">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Información personal
                                </div>
                                <div class="mb-2">
                                    <MazInput id="firstName" v-model="client.firstName" label="Nombre" autocomplete="none" :error="errors.client.firstName" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="lastName1" v-model="client.lastName1" label="Primer apellido" autocomplete="none" :error="errors.client.lastName1">
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="lastName2" v-model="client.lastName2" label="Segundo apellido" autocomplete="none" :error="errors.client.lastName2" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-user-pen"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="email" v-model="client.email" label="Email" autocomplete="none" type="email" :error="errors.client.email">
                                        <template #left-icon>
                                            <i class="fa-regular fa-envelope"></i>
                                        </template>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazPhoneNumberInput v-model="client.phone" show-code-on-list :translations="{
                                        countrySelector: {
                                            placeholder: 'Código país',
                                            error: 'Elige país',
                                        },
                                        phoneInput: {
                                            placeholder: 'Nº teléfono',
                                            example: 'Ej:',
                                        },
                                    }" :no-example="true" :error="errors.client.phone">
                                    </MazPhoneNumberInput>
                                </div>

                                <div class="mb-2">
                                    <MazSelect id="sex" v-model="client.sex" label="Sexo" autocomplete="none" :error="errors.client.sex" :options="sexOptions" required>
                                    </MazSelect>
                                </div>

                                <div class="mb-2">
                                    <MazInput id="dateofbirth" v-model="client.dateofbirth" label="Fecha de
                                                nacimiento" autocomplete="none" type="date" :error="errors.client.dateofbirth" required>
                                    </MazInput>
                                </div>

                                <div class="mb-2">
                                    <MazSelect id="nationality" v-model="client.nationality" label="Nacionalidad" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.nationality">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Documentos
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="document_type" v-model="client.document_type" label="Tipo de documento" autocomplete="none" :error="errors.client.document_type"
                                        :options="documentOptions" required>
                                    </MazSelect>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="nif" v-model="client.nif" label="Nº documento" autocomplete="none" :error="errors.client.nif" required>
                                        <template #left-icon>
                                            <i class="fa-regular fa-passport"></i>
                                        </template>
                                    </MazInput>
                                    <small class="text-muted">* Introduce el número del documento.
                                        Verifica que está bien escrito.</small>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="nif" v-model="client.document_support" label="Nº de soporte" autocomplete="none" required>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="document_expeditionDate" v-model="client.document_expeditionDate" label="Fecha Expedición" autocomplete="none" type="date" :error="errors.client
                                        .document_expeditionDate
                                        " required>
                                    </MazInput>
                                </div>
                                <div class="mb-2">
                                    <MazInput id="document_experationDate" v-model="client.document_experationDate" label="Fecha Expiración" autocomplete="none" type="date" :error="errors.client
                                        .document_experationDate
                                        " required>
                                    </MazInput>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Dirección
                                </div>
                                <div class="mb-2">
                                    <MazInput id="address" v-model="client.address" label="Dirección" autocomplete="none" :error="errors.client.address">
                                        <template #left-icon>
                                            <i class="fa-regular fa-location-dot"></i>
                                        </template>
                                    </MazInput>
                                </div>

                                <div class="mb-2" v-if="client.country == 66">
                                    <MazSelect id="region" v-model="client.region" label="Provincia" :options="regions" option-value-key="id" option-label-key="name" option-input-value-key="name"
                                        search search-placeholder="Buscar..." required :error="errors.client.region">
                                    </MazSelect>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-8">
                                        <MazInput id="locality" v-model="client.locality" label="Ciudad" autocomplete="none" :error="errors.client.locality">
                                        </MazInput>
                                    </div>
                                    <div class="col-4">
                                        <MazInput id="postal_code" v-model="client.postal_code" label="Código Postal" autocomplete="none" :error="errors.client.postal_code">
                                        </MazInput>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="country" v-model="client.country" label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.country">
                                    </MazSelect>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="checkedAddress" id="flexCheckChecked" />
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Mantener esta dirección como dirección
                                        de facturación.
                                    </label>
                                </div>
                            </div>
                            <div v-if="!checkedAddress" class="col-md-6">
                                <div class="mb-3 fs-4" style="font-weight: 500">
                                    Dirección de facturación
                                </div>

                                <div class="mb-2">
                                    <MazInput id="tax_address" v-model="client.tax_address" label="Dirección" autocomplete="none" :error="errors.client.tax_address">
                                        <template #left-icon>
                                            <i class="fa-regular fa-location-dot"></i>
                                        </template>
                                    </MazInput>
                                </div>

                                <div class="mb-2" v-if="client.tax_country == 66">
                                    <MazSelect id="tax_region" v-model="client.tax_region" label="Provincia" :options="regions" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.tax_region">
                                        <template #left-icon>
                                            <MazIcon name="flag" />
                                        </template>
                                    </MazSelect>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-8">
                                        <MazInput id="tax_locality" v-model="client.tax_locality" label="Ciudad" autocomplete="none" :error="errors.client.tax_locality">
                                        </MazInput>
                                    </div>
                                    <div class="col-4">
                                        <MazInput id="tax_postal_code" v-model="client.tax_postal_code" label="Código Postal" autocomplete="none" :error="errors.client.tax_postal_code
                                            ">
                                        </MazInput>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <MazSelect id="tax_country" v-model="client.tax_country" label="Seleccionar país" :options="countries" option-value-key="id" option-label-key="name"
                                        option-input-value-key="name" search search-placeholder="Buscar..." required :error="errors.client.tax_country">
                                    </MazSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" id="submitAddClient" class="btn btn-primary" @click.prevent.stop="addClient">Guardar</button>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>

<style>
#suggestedClients {
    background: white;
    padding: 30px;
    border-left: 1px solid #f7f7f7;
    box-shadow: 0px -2px 50px rgb(50 50 50 / 10%);
}

#suggestedClients .card {
    width: 100%;
    min-width: 250px;
    cursor: pointer;
}

#suggestedClients .card:hover {
    background: #f7f7f7;
}

#suggestedClients h5 {
    margin-bottom: 30px;
}

#suggestedClients i {
    color: #0091ff;
}
</style>

<script>
import ClientService from "@/services/client.service";
import CountryService from "@/services/country.service";
import RegionService from "@/services/region.service";

import Swal from 'sweetalert2'

import { Modal } from "bootstrap";

export default {
    name: 'AddClient',
    emits: [
        "reloadClients"
    ],
    data() {
        return {
            loading: true,
            checkedAddress: true,
            client: {},
            errors: {
                client: {}
            },
            countries: [],
            regions: [],
            sexOptions: [
                {
                    label: "Masculino",
                    value: "m",
                },
                {
                    label: "Femenino",
                    value: "f",
                },
            ],
            documentOptions: [
                {
                    label: "DNI",
                    value: "dni",
                },
                {
                    label: "Pasaporte",
                    value: "passport",
                },
                {
                    label: "Carné de conducir",
                    value: "driving_license",
                },
                {
                    label: "Carta o Documento de Identidad",
                    value: "identity_cart_or_document",
                },
                {
                    label: "Tarjeta de residencia España",
                    value: "residence_cart",
                },
                {
                    label: "Tarjeta de residencia País UE",
                    value: "residence_cart_country_ue",
                },
                {
                    label: "Cédula de ciudadanía",
                    value: "citizenship_card"
                },
                {
                    label: "Cédula de extranjería",
                    value: "foreign_identity_card"
                },
                {
                    label: "Registro civil",
                    value: "civil_registry"
                }
            ],
            error: false,
            myModal: null
        }
    },
    methods: {
        async start() {
            this.loading = true
            await this.getCountries()
            await this.getRegions()
            this.loading = false
        },

        async getCountries() {
            this.countries = await CountryService.getAllCountries();
        },

        async getRegions() {
            var response = await RegionService.getAllRegions();
            this.regions = response.data;
        },

        async validateDNI(dni) {
            var numero, let2, letra;
            var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

            dni = dni.toUpperCase();

            if (expresion_regular_dni.test(dni) === true) {
                numero = dni.substr(0, dni.length - 1);
                numero = numero.replace("X", 0);
                numero = numero.replace("Y", 1);
                numero = numero.replace("Z", 2);
                let2 = dni.substr(dni.length - 1, 1);
                numero = numero % 23;
                letra = "TRWAGMYFPDXBNJZSQVHLCKET";
                letra = letra.substring(numero, numero + 1);
                if (letra != let2) {
                    //alert('Dni erroneo, la letra del NIF no se corresponde');
                    return false;
                } else {
                    //alert('Dni correcto');
                    return true;
                }
            } else {
                //alert('Dni erroneo, formato no válido');
                return false;
            }
        },

        resetErrors() {
            // RESETAMOS LOS ERRORES
            this.errors = {
                client: {},
            };
        },

        async addClient() {
            this.resetErrors();

            var now = new Date();
            var error = false;

            // COMPROBAMOS EL CAMPO firstName
            if (
                this.client.firstName == undefined ||
                this.client.firstName == ""
            ) {
                this.errors.client.firstName = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO lastName1
            if (
                this.client.lastName1 == undefined ||
                this.client.lastName1 == ""
            ) {
                this.errors.client.lastName1 = true;
                error = true;
            }



            // COMPROBAMOS EL CAMPO email
            if (this.client.email == undefined || this.client.email == "") {
                this.client.email = null;
            }

            // COMPROBAMOS EL CAMPO sex
            if (this.client.sex == undefined || this.client.sex == "") {
                this.errors.client.sex = true;
                error = true;
            }

            // COMPROBAMOS QUE LA FECHA DE NACIMIENTO SEA MENOR A LA FECHA DE CHECKIN
            var dateofbirth = new Date(this.client.dateofbirth);
            if (
                this.client.dateofbirth == undefined ||
                dateofbirth >= now ||
                dateofbirth.getFullYear() < 1900
            ) {
                this.errors.client.dateofbirth = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO nif
            if (this.client.document_type == "dni") {
                var result = await this.validateDNI(this.client.nif);

                if (!result) {
                    this.errors.client.nif = true;
                }
            }

            // COMPROBAMOS EL CAMPO document_expeditionDate
            var document_expeditionDate = new Date(
                this.client.document_expeditionDate
            );
            if (

                document_expeditionDate >= now ||
                document_expeditionDate.getFullYear() < 2000
            ) {
                this.errors.client.document_expeditionDate = true;
                error = true;
            }

            // COMPROBAMOS EL CAMPO document_experationDate SOLO SI EL TIPO DE DOCUMENTO NO ES "civil_registry" o "citizenship_card"
            if (this.client.document_type !== "civil_registry" && this.client.document_type !== "citizenship_card") {
                var document_experationDate = new Date(this.client.document_experationDate);
                if (
                    this.client.document_experationDate == undefined ||
                    document_experationDate <= this.client.document_expeditionDate ||
                    document_experationDate < now
                ) {
                    this.errors.client.document_experationDate = true;
                    error = true;
                }
            }

            if (error == true) {
                return;
            } else {
                Swal.fire({
                    title: 'Guardando cliente...',
                    text: 'Por favor, espera mientras se crea la ficha de cliente.',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false
                });

                var response = await ClientService.addClient(this.client);
                Swal.close();

                if (response.status == 201) {
                    this.client = {}

                    Swal.fire({
                        icon: "success",
                        title: "Cliente creado correctamente",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.myModal.hide();
                            this.$emit('reloadClients');
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. " + response.data.message,
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },

    },
    created() {
        this.start()
    },
    mounted() {
        this.myModal = new Modal(document.getElementById('modalAddClient'));
    }

}
</script>